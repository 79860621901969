<template>
  <ValidationObserver @submit="formCheck">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="show"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <div>
          <span v-if="edit" class="pr-1">Edit</span>
          <span v-if="type === 'checkIn'" v-i18n="attendanceTranslation">DLVCIO_TH_5</span>
          <span v-else v-i18n="attendanceTranslation">DLVCIO_TH_6</span>
        </div>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <div class="flex-1">
              <UiDateTimePicker
                v-model="checked.checked_at"
                title="Checked At"
                name="Checked At"
                type="time"
                rules="required"
              />
            </div>
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiSingleSelect
              v-if="!edit"
              v-model="checked.student"
              title="STUDENT"
              label="full_name"
              :image="true"
              :options="studentList || []"
              reduce="id"
              class="flex-1"
              rules="required"
              :search-through-api="true"
              @search="getStudents"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader />
            </template>
            <span v-else>{{ buttonTitle }}</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { fullName } from '@utils/settings/tenant-user.util'
import { Form as ValidationObserver } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { formatDateFromLocalToUTC } from '@/src/utils/moment.util.js'
import dateTimeFormat from '@src/constants/date-time-constants.js'

export default {
  name: 'StudentCheckInModal',
  components: {
    UiModalContainer,
    UiDateTimePicker,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    edit: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkIn',
    },
  },
  emits: ['refetch', 'cancel', 'close'],
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation', // i18n
      isLoading: false,
      studentList: [],
      checked: {
        checked_at: formatDateFromLocalToUTC(new Date(), dateTimeFormat.DATE),
        checked_type: '',
        student: '',
      },
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope?.id,
    }),
    buttonTitle() {
      return this.edit ? 'Update' : 'Mark'
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: 'initializeModalData',
    },
  },
  methods: {
    formatDateFromLocalToUTC,
    initializeModalData() {
      if (this.edit) {
        this.checked.checked_at = this.edit.checked_at
        this.checked.student = this.edit.user_id
      } else if (this.show && !this.studentList.length) {
        this.getStudents()
      }
    },

    getStudents: debounce(function (query) {
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      this.getStudentsWithFilters(payload)
        .then((res) => {
          this.studentList = res.records

          if (this.studentList.length > 0) {
            this.studentList.forEach((record) => {
              fullName(record)
              this.isLoading = true
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }, 250),

    handleClick(status) {
      switch (status) {
        case 'close':
          this.$emit('close')
          break
      }
    },

    clearForm() {
      this.checked.checked_at = ''
      this.checked.student = null
    },
    async formCheck() {
      if (!this.isLoading) {
        try {
          const data = {
            checked_at: this.checked.checked_at,
            is_bulk: false,
            users: [
              {
                user_id: this.checked?.student,
              },
            ],
            checked_type: this.type === 'checkIn' ? 'checked_in' : 'checked_out',
          }
          const campus_data = {
            checked_at: this.checked.checked_at,
            user_id: this.checked.student,
            checked_type: this.type === 'checkIn' ? 'checked_in' : 'checked_out',
          }

          this.isLoading = true
          if (this.edit) {
            campus_data.id = this.edit?.id
            await this.editStudentCampusAttendance(campus_data)
          } else {
            await this.markStudentCampusAttendance(campus_data)
          }

          this.clearForm()

          this.isLoading = false

          this.$emit('refetch')
          this.$emit('close')
        } catch (err) {
          this.isLoading = false
        }
      }
    },
    ...mapActions('student', [
      'loadCampusStudents',
      'markStudentCampusAttendance',
      'getStudentsWithFilters',
      'editStudentCampusAttendance',
    ]),
  },
}
</script>
